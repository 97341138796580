.companyList {
  margin: 0;
}

.CompanyListPhone {
  color: grey;
  font-size: 14px;
}

.CompanyListBtns {
  max-width: 95%;
  overflow: hidden;
}

.CompanyListInfo {
  display: flex;
  flex-wrap: nowrap;
}

.asdasd {
  padding: 0;
}

.nextui-c-kRHeuF.nextui-c-kRHeuF-igNCIse-css.nextui-grid-item.catHeaderLeft {
  display: flex;
  align-items: center;
}

a.compListLinkItem {
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 4px 12px;
  font-size: 13px;
  height: 28px;
  color: black;
}

.compListCard {
  height: 270px;
  padding-right: 15px;
}

.compListItemsRow {
  padding: 0;
  max-height: 42px;
  overflow-y: scroll;
  flex-direction: column;
}

.compImageWrapper {
  display: flex;
  align-items: center;
  flex: 1;
  object-fit: contain;
  height: auto;
  width: 100%;
  flex-direction: column;
}

.compImage {
  height: auto;
  width: 100%;
}

.compListCardCont {
  min-height: 100%;
}

.compListHeadBlock {
  justify-content: space-between;
}

.CompanyListDirectorName {
  font-size: 14px;
  color: #93959f;
}

.CompanyListDirectorTitle {
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.ratingImage {
  margin-right: 6px;
}

.ratingText {
  font-size: 14px;
}

.CompanyListDirector {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 4px;
}

.CompanyListDescription {
  font-size: 14px;
  line-height: 21px;
  color: #62646c;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
