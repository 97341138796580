.createOrderLink.mobile,
.headerLink.mobile {
  display: none;
}

@media (max-width: 600px) {
  .createOrderLink {
    display: none;
  }
  .createOrderLink.mobile {
    display: block;
  }
}

@media (max-width: 700px) {
  .headerLink {
    display: none;
  }

  .headerLink.mobile {
    display: block;
  }
}

.createOrderForm {
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
  }
}

.flexFormContainer {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  width: 100%;
}

.dropdown {
  padding: 7px 10px;
  border-radius: 0.875rem;
  margin-bottom: 10px;
  border: none;
  font-size: 0.875rem;
  height: 40px;
  // color: #6a6969;
  appearance: none;
  background: url("../../public/arrow.svg") no-repeat right #f1f3f5;
  background-position-x: calc(100% - 10px);
}

option:not(:first-of-type) {
  color: black;
}

.catListWrapper.createOrder {
  width: 400px;
}

@media (max-width: 400px) {
  .catListWrapper.createOrder {
    width: 100%;
  }
}

.createOrderHeader {
  display: flex;
  align-items: baseline;
}
.createOrderBack {
  margin-right: 15px;
}
.uploadImageButton {
  border-radius: 15px;
  border: none;
  height: 100%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='50' fill='none'%3E%3Cpath fill='%23999' d='M47.187 6.151l-3.816 36.51a2.525 2.525 0 0 1-2.363 2.256l-.467-10.189 1.103.116L44.67 5.888 12.566 2.533l-.424 4.06-2.557.117.463-4.441A2.532 2.532 0 0 1 12.83.014l32.104 3.355a2.532 2.532 0 0 1 2.254 2.782zm-9.085 3.052l1.683 36.67a2.53 2.53 0 0 1-2.413 2.645l-32.244 1.48a2.53 2.53 0 0 1-2.645-2.413L.801 10.915A2.53 2.53 0 0 1 3.213 8.27l32.244-1.48a2.53 2.53 0 0 1 2.645 2.413zM4.664 39.882l32.243-1.48-1.334-29.083-32.243 1.48 1.334 29.083zm30.579-10.149a3.797 3.797 0 0 0-1.01-2.408l-2.266-2.444a1.9 1.9 0 0 0-2.757.126l-4.103 4.75-8.911-10.976a1.893 1.893 0 0 0-2.964.022L6.267 27.98a3.796 3.796 0 0 0-.769 2.47l.373 8.109 29.715-1.363-.343-7.463zM30.031 19.71a3.48 3.48 0 0 0 3.318-3.636 3.479 3.479 0 0 0-3.637-3.318 3.479 3.479 0 0 0-3.317 3.637 3.479 3.479 0 0 0 3.636 3.317z'/%3E%3C/svg%3E")
    no-repeat 50% 40%;
  height: 200px;
  cursor: pointer;
}
.upload__image-wrapper {
  display: flex;
}
.uploadImageButtonText {
  padding-top: 70px;
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 14px;
  padding-right: 30px;
  padding-left: 30px;
}
.removeUploadImageButton {
  border-radius: 15px;
  height: auto;
}
.uploadDeleteImage {
  min-width: 50%;
}

.uploadImageList {
  display: flex;
  flex-wrap: wrap;
}
.image-item {
  position: relative;
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-right: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
  img {
    border-radius: 15px;
  }
}

.image-item__btn-wrapper {
  position: absolute;
  right: -10%;
  display: flex;
  flex-direction: column;
  top: -7px;
  justify-content: space-around;
  button {
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 0.5px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .upload__image-wrapper {
    flex-direction: column;
  }
}
