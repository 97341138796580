.card-infotitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.mainpageGridContainer {
  padding: 0 !important;
}

@media (max-width: 600px) {
  .layoutContainer {
    padding: 0;
  }
}

.listItemInfo {
  color: grey;
  font-size: 16px;
  line-height: 100%;
}

.mainpage-image {
  width: 100%;
  height: 165px;
  object-fit: cover;
}
