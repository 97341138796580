/* This stylesheet generated by Transfonter (https://transfonter.org) on July 28, 2017 4:22 PM */

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Black Italic'),
    url('Rubik-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Bold Italic'),
    url('Rubik-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Light'),
    url('Rubik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Medium'),
    local('Rubik-Medium'),
    url('Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Italic'),
    url('Rubik-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Bold'),
    url('Rubik-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Light Italic'),
    url('Rubik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik'),
    url('Rubik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Medium Italic'),
    url('Rubik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik SemiBold'),
    url('Rubik-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Extra Bold'),
    url('Rubik-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Extra Bold Italic'),
    url('Rubik-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Rubik';
  src:
    local('Rubik Black'),
    url('Rubik-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* #### Generated By: http://www.cufonfonts.com #### */
/*
@font-face {
  font-family: 'Winston Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston Regular'),
    url('WinstonRegular.woff') format('woff');
}

@font-face {
  font-family: 'Winston ExtraLight';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston ExtraLight'),
    url('WinstonExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Winston Light';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston Light'),
    url('WinstonLight.woff') format('woff');
}

@font-face {
  font-family: 'Winston Regular';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston Regular'),
    url('WinstonRegular.woff') format('woff');
}

@font-face {
  font-family: 'Winston Medium';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston Medium'),
    url('WinstonMedium.woff') format('woff');
}

@font-face {
  font-family: 'Winston SemiBold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston SemiBold'),
    url('WinstonSemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Winston ExtraBold';
  font-style: normal;
  font-weight: normal;
  src:
    local('Winston ExtraBold'),
    url('WinstonExtraBold.woff') format('woff');
}

 */
