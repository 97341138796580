.profileCompanyList {
  margin: 0;
  display: flex;
  flex-direction: column;
}
.photoIcon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.318 6h3.4c.446 0 .608.046.77.134.163.087.291.215.378.378.088.163.134.324.134.77v12.436c0 .446-.046.608-.134.77a.908.908 0 0 1-.378.378c-.162.088-.324.134-.77.134H3.282c-.446 0-.607-.046-.77-.134a.908.908 0 0 1-.378-.378c-.088-.162-.134-.324-.134-.77V7.282c0-.446.046-.607.134-.77a.909.909 0 0 1 .378-.378c.163-.088.324-.134.77-.134h3.4l.882-2.351A1 1 0 0 1 8.5 3h7a1 1 0 0 1 .936.649L17.318 6zM12 18a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm3-5a3 3 0 1 1-6 0 3 3 0 0 1 6 0z' fill='%23000'/%3E%3C/svg%3E");
  background-position: center;
  background-size: 25px;
  background-color: #939393;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
form.photoIconForm {
  font-size: 0;
}