.chatCreateGroup {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 2;
  width: calc(100% - 2px);
  overflow: scroll;
  background-color: #fff;
  min-height: 498px;
  max-height: 498px;
  border-radius: 1em;
}
.chatCreateGroup.hidden {
  display: none;
}

.inviteSearchbar {
  margin: 0.5em 0.6em !important;
  padding: 0.2em 0.6em !important;
}

.chatMembersList {
  // padding: 0 1em;
}
.chatCreateGroup .cs-conversation-list > div > ul {
  min-height: 429px;
}
.chatMember {
  position: relative;
  overflow: hidden;
  padding: 0.4em 1em;
  border-bottom: 1px solid #e0efff;
  input {
    display: block;
    position: absolute;
    transform: translateY(55%);
    width: 182%;
    cursor: pointer;
  }
}

.newGroup {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 2;
  width: calc(100% - 2px);
  overflow: scroll;
  background-color: #fff;
  min-height: 498px;
  border-radius: 1em;
}

.newGroup.hidden {
  display: none;
}

.newGroupName .cs-conversation-header__user-name {
  display: flex;
  justify-content: center;
}

.chosenUsers {
  margin: 1em;
  border: 1px solid #bdd9ef;
  border-radius: 10px;
  overflow: hidden;
  .cs-conversation {
    border-bottom: 1px solid #bdd9ef;
    &:last-child {
      border-bottom: none;
    }
  }
}

.groupNameInputWrapper {
  margin: 1em;
  margin-top: 1em;
}

// .withAva {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 30px;
//   margin-left: 30px;
//   margin-bottom: 40px;
// }

.ChatGroupAva {
  display: flex;
  align-items: center;
  .photoIcon {
    width: 50px;
    height: 50px;
  }
}

.dropdownOrders {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background: #f1f1f1;
  color: #3b3b3b;
}
