.chatContainer {
  position: fixed;
  right: 50px;
  bottom: 50px;
  border-radius: 1em;
}

.buttonChat {
  min-width: 3.5rem;
  height: 3.5rem;
}

.chatBlock {
  width: 400px;
  height: 500px;
  position: relative;
  overflow-y: scroll;
  border-radius: 1em;
}

.chatlist {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.chatlistContainer {
  z-index: 2;
  position: absolute !important;
  top: 2px;
  left: 2px;
  transform: translate(0%);
  width: calc(100% - 3px);
  background-color: #fff !important;
  height: 99% !important;
  border-radius: 1em;
  overflow: scroll;
  ul li {
    margin-bottom: 0 !important;
  }
}

.chatlistContainer.hidden {
  z-index: 0;
}

.closeChatIcon {
  cursor: pointer;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

@media (max-width: 500px) {
  .chatBlock {
    width: 100vw;
    height: 75vh;
    position: relative;
  }

  .chatContainer {
    position: fixed;
    right: 0px;
    bottom: 0px;
  }
  .buttonChat {
    right: 30px;
    bottom: 30px;
  }
}

.chatHeaderContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatHeaderSearch {
  width: 100%;
  margin: 0 15px !important;
}

span.nextui-c-bDGMeb.nextui-c-bDGMeb-jzMUFa-isAuto-true.nextui-c-bDGMeb-jeNDWm-isRight-true.nextui-c-bDGMeb-gLOJOp-cv.nextui-c-bDGMeb-iefzdUn-css.nextui-button-icon.nextui-button-icon-right {
  display: none;
}

.chatHeaderDropdown {
  padding: 0;
}

.mainMainContainer {
  border-radius: 1em;
}
.chatItem {
  padding: 0.4em 1em !important;
  border-bottom: 1px solid #e0efff !important;
}

.dropdownChooseAdInChat {
  width: 100%;
  min-width: 400px;
}

.dropdownChooseAdInChatItem {
  height: auto;
  span {
    display: flex;
    flex-wrap: nowrap;
    height: auto;
  }
}

.chatDropdown {
  width: 100%;
}
