input.signinPhoneInput {
  padding: 0 12px;
  font-size: 0.875rem;
  background: #f1f3f5;
  border: none;
  color: #11181c;
  outline: none;
  width: 100%;
  min-width: 0;
  border-radius: 0.875rem;
  height: 40px;
}

input.signinPhoneInput::placeholder {
  color: #889096;
}

.signinContainer {
  height: 100vh;
  width: 100%;
}

.linkAuth {
  padding: 10px 15px;
  background: #f2f2f2;
  border-radius: 10px;
  color: #353434;
}

@media (max-width: 400px) {
  .signinContainer {
    padding: 0;
  }
}
