.services {
  display: flex;
  align-items: center;
}

.servicesLink {
  padding-right: 15px;
}

@media (max-width: 440px) {
  .services {
    display: none;
  }
}
